import React, { Component } from 'react';
import styled from 'styled-components';
import shopixLogo from 'assets/shopix-footer-logo.png';
import { media } from 'style/style-utils'
import Space from 'components/common/Space';
import Text from 'components/common/Text';
import Above from './Above';
import ShapeSeparator from './ShapeSeparator';
import Modal from 'components/common/Modal';
import FacebookPageMobileIframe from 'components/island/FacebookPageMobileIframe';

const ColorWrapper = styled.div`
  background-color: #00a5f0;
  width: 100%;
`;

const Wrapper = styled.div`
  max-width: ${props => props.theme.contentWidth};
  min-height: 100px;
  margin: auto;
  display: flex;
  align-items: center;
  padding: 0 40px;

  ${media.tablet`
    flex-flow: column;
    padding: 0 10px 15px 10px;
  `}
`;

const ShopixImage = styled.img`
  width: 82px;
`;

const Link = styled.div`
  margin: ${props => (props.isIslandPage ? 'auto' : '0')};
  font-family: ${props => props.theme.fonts.text}, sans-serif;
  font-weight: bold;
  font-size: 13px;
  color: white;
  text-decoration: none;
  cursor: pointer;
`;

const FooterText = styled(Text)`
  margin: 0;
  color: white;
  font-size: 13px;
`;

const Box = styled.div`
  flex: 50%;
`;

const FacebookPageBox = styled.div`
  flex: 50%;

  ${media.tablet`
    display: none;
  `}
`;

const InnerBoxContainer1 = styled.div`
  justify-content: flex-start;
  display: flex;
  align-items: center;

  ${media.tablet`
    flex-flow: column;
  `}
`;

const InnerBoxContainer = styled.div`
  justify-content: flex-end;
  display: flex;
  align-items: center;

  ${media.tablet`
    flex-flow: column;
  `}
`;

const RightTextContainer = styled.div`
  width: 130px;
  text-align: right;

  ${media.tablet`
    width: 100%;
    text-align: center;
    padding-bottom: 12px;
  `}
`;

class Footer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showModal: false
    }

    this.toggleModal = this.toggleModal.bind(this);
  }

  toggleModal() {
    this.setState({
      showModal: !this.state.showModal
    });
  }

  render() {
    const { text, legal, isIslandPage, facebookPageUrl } = this.props;
    const { showModal } = this.state;

    return (
      <>
        <Above text={text}/>
        <ShapeSeparator/>
        <ColorWrapper>
          <Wrapper>

            <Space space={0} tablet={10} />

            {
              (isIslandPage ? (
                <FacebookPageBox>
                  <FacebookPageMobileIframe url={facebookPageUrl}/>
                </FacebookPageBox>
              ) : null)
            }

            <Box>
              <InnerBoxContainer1>
                <Link onClick={this.toggleModal} to="#" isIslandPage={isIslandPage}>
                  Mentions Légales
                </Link>
              </InnerBoxContainer1>
            </Box>

            <Space space={0} tablet={10} />

            <Box>
              <InnerBoxContainer>
                <RightTextContainer>
                  <FooterText>
                    En métropole sous la marque Shopix
                  </FooterText>
                </RightTextContainer>
                <a href="https://www.shopix.fr/"><ShopixImage src={shopixLogo}/></a>
              </InnerBoxContainer>
            </Box>

            {(showModal === true ?
              (
                <Modal onClose={this.toggleModal}>
                  <div dangerouslySetInnerHTML={{__html: legal}}></div>
                </Modal>
              ) :
              null
            )}

          </Wrapper>
        </ColorWrapper>
      </>
    );
  }
}

export default Footer;
