import React from 'react';
import styled from 'styled-components';
import { media, customMedia } from 'style/style-utils';

const StaticCategoryWrapper = styled.div`
  display: inline-block;
  font-family: ${props => props.theme.fonts.text}, sans-serif;
  font-weight: 400;
  font-size: 90%;
  background: ${props => props.color};
  width: 12.5%;
  padding: 17px 1.2% 17px 1.2%;
  color: white;

  ${customMedia(1094)`
    padding: 17px 1% 17px 1%;
    font-size: 80%;
  `}

  ${customMedia(959)`
    padding: 17px 0.8% 17px 0.8%;
    font-size: 75%;
  `}

  ${media.tablet`
    width: 25%;
    padding: 17px 1% 17px 1%;
    font-size: 90%;
  `}

  ${media.phone`
    width: 25%;
    padding: 17px 2% 17px 2%;
    font-size: 80%;
  `}

  ${customMedia(464)`
    width: 25%;
    padding: 13px 1% 13px 2%;
    font-size: 50%;
  `}
`;

const Icon = styled.img`
  width: 22px;
  height: 22px;
  float: right;
  margin-top: -2px;

  ${customMedia(865)`
    width: 19px;
    height: 19px;
    margin-top: 0px;
  `}

  ${customMedia(464)`
    width: 14px;
    height: 14px;
    margin-top: -2px;
  `}
`;

const StaticCategory = ({ category }) => {
  return (
    <StaticCategoryWrapper color={category.color}>
      {category.title}
      <Icon src={category.icon}/>
    </StaticCategoryWrapper>
  );
}

export default StaticCategory;
