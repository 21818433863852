import responsiveSizes from './responsive-sizes';

const theme = {
  colors: {
    black: '#000000',
    lightGrey: '#e7e7e7',
  },
  fonts: {
    heading: 'Open Sans',
    text: 'Open Sans',
  },
  contentWidth: '1171px',
  responsiveSizes: {
    desktop: `${responsiveSizes.desktop}px`,
    tablet: `${responsiveSizes.tablet}px`,
    phone: `${responsiveSizes.phone}px`,
  },
};

export default theme;
