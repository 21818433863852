import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import { media } from 'style/style-utils';

const Wrapper = styled.div`
  display: block;
  text-align: center;
  margin: auto;
  padding: 52px 0 0 0;
  background: linear-gradient(${props => props.theme.colors.lightGrey}, white);

  ${media.phone`
    padding: 30px 0 15px 0;
  `}
`;

const ImgWrapper = styled.div`
  width: 220px;
  margin: auto;

  ${media.phone`
    width: 175px;
  `}
`

const Header = ({ logoImage }) => {
  return (
    <Wrapper>
      <Link to="/">
        <ImgWrapper>
          <img alt='DIAMANT distribution logo' width='220' src={logoImage.url} />
        </ImgWrapper>
      </Link>
    </Wrapper>
  )
};

export default Header;
