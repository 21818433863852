import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';
import { media } from 'style/style-utils';
import closeMenuButtonIcon from 'assets/menu-button-close.png';

const modalRoot = (typeof document !== 'undefined' ? document.getElementById('modal-root') : null);

const Background = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0,0,0,0.3);
  z-index: 100;
`;

const ContentWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 60px 20px 20px 20px;
  background: #fff;
  border-radius: 8px;
  height: 80%;
  width: 50%;
  margin: 20px;
  position: relative;
  box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
  overflow: auto;
  text-align: center;
  font-family: "Open Sans";

  ${media.tablet`
    width: 80%;
  `}

  ${media.phone`
    width: 90%;
  `}
`;

const CloseButton = styled.div`
  width: 22px;
  height: 22px;
  cursor: pointer;
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 2;
  background-image: url(${closeMenuButtonIcon});
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
`;

class Modal extends Component {
  render() {
    const { children, onClose } = this.props;

    return ReactDOM.createPortal(
      <Background onClick={onClose}>
        <ContentWrapper onClick={(e) => { e.stopPropagation() }}>
          <CloseButton onClick={onClose}/>
          {children}
        </ContentWrapper>
      </Background>,
      modalRoot
    );
  }
}

export default Modal;
