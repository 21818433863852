import React from 'react';
import styled from 'styled-components';
import Text from 'components/common/Text';
import Space from  'components/common/Space';
import { media } from 'style/style-utils'
import StaticCategories from './StaticCategories';

const StaticCategoriesWrapper = styled.div`
  max-width: ${props => props.theme.contentWidth};
  margin: auto;
`;

const TextAbove = styled(Text)`
  font-size: 12px;
  text-align: center;

  ${media.tablet`
    margin: 0 10px;
  `}
`;

const Above = ({ island, text }) => {
  return (
    <StaticCategoriesWrapper>
      <StaticCategories/>
      <Space space={10} />
      <TextAbove>
        {text}
      </TextAbove>
      <Space space={10} />
    </StaticCategoriesWrapper>
  );
}

export default Above;
