import { createGlobalStyle } from 'styled-components';

import OpenSansLight from 'assets/OpenSans-Light.ttf';
import OpenSansRegular from 'assets/OpenSans-Regular.ttf';
import OpenSansBold from 'assets/OpenSans-Bold.ttf';
import OpenSansExtraBold from 'assets/OpenSans-ExtraBold.ttf';
import OpenSansSemiBold from 'assets/OpenSans-SemiBold.ttf';

const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: 'Open Sans';
    src: url('${OpenSansRegular}');
  }

  @font-face {
    font-family: 'Open Sans';
    src: url('${OpenSansLight}');
    font-weight: 300;
  }

  @font-face {
    font-family: 'Open Sans';
    src: url('${OpenSansSemiBold}');
    font-weight: 400;
  }

  @font-face {
    font-family: 'Open Sans';
    src: url('${OpenSansBold}');
    font-weight: bold;
  }

  @font-face {
    font-family: 'Open Sans';
    src: url('${OpenSansExtraBold}');
    font-weight: 800;
  }

  html {
    box-sizing: border-box;
    font-size: 1em;
    margin: 0;
    padding: 0;
  }

  *, *::before, *::after {
		box-sizing: inherit;
	}

  h1, h2, h3, h4, p {
    font-family: 'Open Sans';
  }

  h1 {
    font-size: 2.074em;
  }

  h2 {
    font-size: 1.728em;
  }

  body {
    margin: 0;
    padding: 0;
  }
`;

export default GlobalStyle;