import React from 'react';
import styled from 'styled-components';

const StyledSvg = styled.svg`
  height: 30px;
  width: 100%;
  display: block;
  fill: #00a5f0;
  margin-bottom: -1px;
`;

const ShapeSeparator = () => {
  return (
    <StyledSvg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1000 100"
      preserveAspectRatio="none"
    >
      <path d="M1000 0 V1000 100 H0 Z"/>
    </StyledSvg>
  );
}

export default ShapeSeparator;
