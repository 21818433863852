import styled from 'styled-components';
import { media } from '../../style/style-utils';

const Space = styled.div`
  height: ${props => props.space}px;
  ${props => (props.phone === undefined  ? null : media.phone`height: ${props.phone}px`)}
  ${props => (props.tablet === undefined ? null : media.tablet`height: ${props.tablet}px`)}
  ${props => (props.desktop === undefined ? null : media.desktop`height: ${props.desktop}px`)}
`;

export default Space;
