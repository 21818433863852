import React from 'react';
import StaticCategory from './StaticCategory';

import iconJardin from 'assets/footer-icon-jardin.png';
import iconPleinAir from 'assets/footer-icon-plein-air.png';
import iconBricolage from 'assets/footer-icon-bricolage.png';
import iconAutoMoto from 'assets/footer-icon-auto-moto.png';
import iconMaison from 'assets/footer-icon-maison.png';
import iconCuisine from 'assets/footer-icon-cuisine.png';
import iconMenage from 'assets/footer-icon-menage.png';
import iconBienEtre from 'assets/footer-icon-bien-etre.png';

const StaticCategories = () => {
  const categories = [
    {
      title: 'JARDIN',
      color: '#8aa216',
      icon: iconJardin,
    },
    {
      title: 'PLEIN AIR',
      color: '#115fa4',
      icon: iconPleinAir,
    },
    {
      title: 'BRICOLAGE',
      color: '#5c5b59',
      icon: iconBricolage,
    },
    {
      title: 'AUTO-MOTO',
      color: '#d9071d',
      icon: iconAutoMoto,
    },
    {
      title: 'MAISON',
      color: '#84357f',
      icon: iconMaison,
    },
    {
      title: 'CUISINE',
      color: '#ed870d',
      icon: iconCuisine,
    },
    {
      title: 'MÉNAGE',
      color: '#de1372',
      icon: iconMenage,
    },
    {
      title: 'BIEN-ÊTRE',
      color: '#1c8782',
      icon: iconBienEtre,
    },
  ];

  const categoriesMarkups = categories.map((category) =>
    <StaticCategory category={category} key={category.title} />
  );

  return (
    <div>
      {categoriesMarkups}
    </div>
  );
}

export default StaticCategories;
